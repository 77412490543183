import { forwardRef } from 'react';

import React, { useState } from 'react';
import Grid from '@material-ui/core/Grid';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { Paper } from '@material-ui/core';
import DBConnection from './Constants/DBConnection';
import IconButton from '@material-ui/core/IconButton';
import MaterialTable from 'material-table';
import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import EditIcon from '@material-ui/icons/Edit';
import UpdateIcon from '@material-ui/icons/Update';
import Navigation from './Navigation';



const TableIcon = {

  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
}


const db = new DBConnection();
const rows = [];
export default class TableWord extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tabledata: [],
    }
  }

  async componentWillMount() {
    await this.load();
  }

  handleOpenEdit = (params) => {

  }
  handleUpdate = async (params) => {
    let result = await db.UpdateSign(params.wid, params.hamnosys, params.nmham);
    console.log(result);
    if (result !== 'undefined') {

      alert("updated");
      //console.log(this.state.tabledata);
    }
  }


  load = async () => {

    let result = await db.loadAllWords();
    console.log(result);
    if (result !== 'undefined') {

      this.setState({
        tabledata: result
      })
      //console.log(this.state.tabledata);
    }
  }

  render() {
    const state = {

      columns: [
        { title: 'WID', field: 'wid' },
        { title: 'English', field: 'engw' },
        { title: 'Punjabi', field: 'pbw' },
        { title: 'Hindi', field: 'hiw' },
        { title: 'hmnosys', field: 'hamnosys', cellStyle: {
          fontFamily:"hamnosysunicoderegular",
          fontSize:'2em',
        }},
        
        { title: 'nmham', field: 'nmham' },
        { title: 'tag', field: 'tag' },
        {
          title: 'Options', render: rowData =>
            <>
              {/*             
            <IconButton aria-label="Edit"
            onClick={() => { this.handleOpenEdit(rowData) }}
            >
              <EditIcon />
            </IconButton> */}

              <IconButton aria-label={rowData.wid}
                onClick={() => { this.handleUpdate(rowData) }}
              >
                <UpdateIcon />
              </IconButton>
            </>
        },
      ],

      data: [
        { wid: 'wid', engw: 'engw', pbw: 'pbw', hiw: 'hiw', hamnosys: 'hamnosys', nmham: 'nmham', tag: 'tag' },

      ],

    };

    return (

      <React.Fragment>
        <Navigation />
        <Grid container>
          <Grid item md={12}>
            <Paper
              style={{ width: "1200px", overflow: "scroll" }}
            >
              <MaterialTable
                icons={TableIcon}
                title="Editable Example"
                columns={state.columns}
                data={this.state.tabledata}
              />


            </Paper>
          </Grid>
        </Grid>
      </React.Fragment>

    );
  }


}

const styles = {

  paperBlock: {
    padding: 20,
  }
};