import React, { Component, Fragment } from 'react'
import Button from '@material-ui/core/Button'
import { Grid } from '@material-ui/core'
import TableWord from './TableWord'

export default class SectionWord extends Component {
    render() {
        return (
            <Fragment>
                <Grid container>
                    <Grid item>
                        <TableWord />

                    </Grid>
                   

                </Grid>

            </Fragment>
        )
    }
}

