import React, { useState } from 'react';

import { Carousel, Tabs } from 'antd';

import 'antd/dist/antd.css';
import { Button } from 'antd';
import { render } from 'react-dom';
import DbConnection from './Constants/DBConnection';
import { Box, FormControl, Grid, InputLabel, MenuItem, Paper, Select, TextField, Typography, withStyles } from '@material-ui/core';
const { TabPane } = Tabs;

const useStyles = theme => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 80,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    
});


const db = new DbConnection();
class KeyBoard extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            SiGML: '',
            hamcode: '',
            hnm_mouthgesture: '',
            hnm_body: '',
            hnm_head: '',
            hnm_shoulder: '',
            hnm_eyegaze: '',
            hnm_eyebrows: '',
            hnm_eyelids: '',
            hnm_nose: '',
            cursorPos:0,
        }
        this.textarea = React.createRef();
    }
    update_code = (ch) => {
        
        var cursorPos = this.state.cursorPos;
        
        var v = this.state.hamcode;     
        var textBefore = v.substring(0,  cursorPos);
        var textAfter  = v.substring(cursorPos, v.length);
        var final= textBefore + ch + textAfter;
        this.setState({
            hamcode: final,
            cursorPos : cursorPos+1,
        });
    }
    update_code_body = (params) => {
        this.setState({
            hnm_body: params,
        });
    }

    update_code_eye_brows = (params) => {
        this.setState({
            hnm_eyebrows: params,
        });
    }

    update_code_eye_gaze = (params) => {
        this.setState({
            hnm_eyegaze: params,
        });
    }

    update_code_eye_lids = (params) => {
        this.setState({
            hnm_eyelids: params,
        });
    }

    update_code_head = (params) => {
        this.setState({
            hnm_head: params,
        });
        console.log(params);
    }

    update_code_nose = (params) => {
        this.setState({
            hnm_nose: params,
        });
    }

    update_code_shoulder = (params) => {
        this.setState({
            hnm_shoulder: params,
        });
    }
    update_code_mouth_gesture = (value) => {
        this.setState({
            hnm_mouthgesture: value,
        });
    }


    generateSiGML = async () => {
        var nmham = {};
        console.log('nmham : ' + nmham);

        if (this.state.hnm_mouthgesture != '') {
            nmham['hnm_mouthgesture'] = this.state.hnm_mouthgesture;
        }

        if (this.state.hnm_body != '') {
            nmham['hnm_body'] = this.state.hnm_body;
        }

        if (this.state.hnm_head !== '') {
            // var obj = {};
            nmham['hnm_head'] = this.state.hnm_head;

            // nmham.push(obj);
        }

        if (this.state.hnm_shoulder != '') {
            nmham['hnm_shoulder'] = this.state.hnm_shoulder;
        }

        if (this.state.hnm_eyegaze != '') {
            nmham['hnm_eyegaze'] = this.state.hnm_eyegaze;
        }

        if (this.state.hnm_eyebrows != '') {
            nmham['hnm_eyebrows'] = this.state.hnm_eyebrows;
        }

        if (this.state.hnm_eyelids != '') {
            nmham['hnm_eyelids'] = this.state.hnm_eyelids;
        }

        if (this.state.hnm_nose != '') {
            nmham['hnm_nose'] = this.state.hnm_nose;
        }
        console.log('sent : ' + nmham);

        var res = await db.GenerateSiGML(this.state.hamcode, nmham)
        this.setState({
            SiGML: res,
            nmham: nmham

        })
        // console.log(res);
        this.get(res);
    }


    play = async () => {

    }
    get = (res) => {
        const data = this.state.SiGML;

        var txt = document.getElementsByClassName('txtaSiGMLText');
        txt[0].innerHTML = res;
        console.log(res);
        var btn = document.getElementsByClassName('bttnPlaySiGMLText');
        btn[0].click();

        this.setState({
            statusMsg: "Playing",
        });

    }
    playAgain = () => {
        var btn = document.getElementsByClassName('bttnPlaySiGMLText');
        btn[0].click();
    }

    clearData = () => {
        this.setState({
            hamcode: '',
            hnm_mouthgesture: '',
            hnm_body: '',
            hnm_head: '',
            hnm_shoulder: '',
            hnm_eyegaze: '',
            hnm_eyebrows: '',
            hnm_eyelids: '',
            hnm_nose: ''
        });

    }
    handleHamCodeChange = (event) => {
        this.setState({ hamcode: event.target.value });
      
        // var tb = document.getElementById('txt');

        // tb.setSelectionRange(this.state.cursorPos+1, this.state.cursorPos+1);

    }

    deleteText = () => {
        if(this.state.cursorPos>0){
            var cursorPos = this.state.cursorPos;
        
        var v = this.state.hamcode;     
        var textBefore = v.substring(0,  cursorPos-1);
        var textAfter  = v.substring(cursorPos, v.length);
        var final= textBefore + textAfter;
        this.setState({
            hamcode: final,
            cursorPos : cursorPos-1,
        });
        }
    }
    handleKeyDown = (event) =>{
        console.log(event.code);
    }
    
    handleFocus = () => {
        var tb = document.getElementById('txt');
        console.log("focused" );
        console.log(tb.selectionStart );

        this.setState({
            cursorPos:tb.selectionStart,
        });
       // this.textarea.current.selectionStart = this.state.cursorPos;
       // console.log(this.textarea);
    }
    convertDigits = (n) => {
        return n > 9 ? "" + n : "0" + n;
    }


    render() {
        const { classes } = this.props;

        return (
            <div>
                <Tabs defaultActiveKey="1" >
                    <TabPane tab="HandShape" key="1">
                        <button title="hamfist" className="btn btn-default hm" onClick={() => { this.update_code('') }}></button>
                        <button title="hamflathand" className="btn btn-default hm" onClick={() => { this.update_code('') }}></button>
                        <button title="hamfinger2" className="btn btn-default hm" onClick={() => { this.update_code('') }}></button>
                        <button title="hamfinger23" className="btn btn-default hm" onClick={() => { this.update_code('') }}></button>
                        <button title="hamfinger23spread" className="btn btn-default hm" onClick={() => { this.update_code('') }}></button>
                        <button title="hamfinger2345" className="btn btn-default hm" onClick={() => { this.update_code('') }}></button>
                        <button title="hampinch12" className="btn btn-default hm" onClick={() => { this.update_code('') }}></button>
                        <button title="hampinchall" className="btn btn-default hm" onClick={() => { this.update_code('') }}></button>
                        <button title="hampinchopen" className="btn btn-default hm" onClick={() => { this.update_code('') }}></button>
                        <button title="hamcee12" className="btn btn-default hm" onClick={() => { this.update_code('') }}></button>
                        <button title="hamceeall" className="btn btn-default hm" onClick={() => { this.update_code('') }}></button>
                        <button title="hamceeopen" className="btn btn-default hm" onClick={() => { this.update_code('') }}></button>
                        <button title="hamthumboutmod" className="btn btn-default hm" onClick={() => { this.update_code('') }}></button>
                        <button title="hamthumbacrossmod" className="btn btn-default hm" onClick={() => { this.update_code('') }}></button>
                        <button title="hamthumbopenmod" className="btn btn-default hm" onClick={() => { this.update_code('') }}></button>
                        <button title="hamdoublebent" className="btn btn-default hm" onClick={() => { this.update_code('') }}></button>
                        <button title="hamfingerstraightmod" className="btn btn-default hm" onClick={() => { this.update_code('') }}></button>
                        <button title="hamfingerbendmod" className="btn btn-default hm" onClick={() => { this.update_code('') }}></button>
                        <button title="hamfingerhookmod" className="btn btn-default hm" onClick={() => { this.update_code('') }}></button>
                        <button title="hamdoublehooked" className="btn btn-default hm" onClick={() => { this.update_code('') }}></button>
                        <button title="hamthumb" className="btn btn-default hm" onClick={() => { this.update_code('') }}></button>
                        <button title="hamindexfinger" className="btn btn-default hm" onClick={() => { this.update_code('') }}></button>
                        <button title="hammiddlefinger" className="btn btn-default hm" onClick={() => { this.update_code('') }}></button>
                        <button title="hamringfinger" className="btn btn-default hm" onClick={() => { this.update_code('') }}></button>
                        <button title="hampinky" className="btn btn-default hm" onClick={() => { this.update_code('') }}></button>
                        <button title="hambetween" className="btn btn-default hm" onClick={() => { this.update_code('') }}></button>
                        <button title="hamfingertip" className="btn btn-default hm" onClick={() => { this.update_code('') }}></button>
                        <button title="hamfingernail" className="btn btn-default hm" onClick={() => { this.update_code('') }}></button>
                        <button title="hamfingerpad" className="btn btn-default hm" onClick={() => { this.update_code('') }}></button>
                        <button title="hamfingermidjoint" className="btn btn-default hm" onClick={() => { this.update_code('') }}></button>
                        <button title="hamfingerbase" className="btn btn-default hm" onClick={() => { this.update_code('') }}></button>
                        <button title="hamfingerside" className="btn btn-default hm" onClick={() => { this.update_code('') }}></button>

                    </TabPane>
                    <TabPane tab="Orientation" key="2">
                        <Carousel>
                            <div>
                                <Grid container justify="center" spacing={2}>
                                    <Grid item >

                                        <table>
                                            <tbody>

                                                <tr>
                                                    <td></td>
                                                    <td></td>
                                                    <td>
                                                        <button title="hamextfingeru" className="btn btn-default hm" onClick={() => { this.update_code('') }}></button>
                                                    </td>
                                                    <td></td>
                                                    <td></td>

                                                </tr>
                                                <tr>
                                                    <td></td>
                                                    <td>
                                                        <button title="hamextfingerul" className="btn btn-default hm" onClick={() => { this.update_code('') }}></button>
                                                    </td>
                                                    <td></td>
                                                    <td>
                                                        <button title="hamextfingerur" className="btn btn-default hm" onClick={() => { this.update_code('') }}></button>
                                                    </td>
                                                    <td></td>

                                                </tr>
                                                <tr>
                                                    <td>
                                                        <button title="hamextfingerl" className="btn btn-default hm" onClick={() => { this.update_code('') }}></button>
                                                    </td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td>
                                                        <button title="hamextfingerr" className="btn btn-default hm" onClick={() => { this.update_code('') }}></button>
                                                    </td>

                                                </tr>
                                                <tr>
                                                    <td></td>
                                                    <td>
                                                        <button title="hamextfingerdl" className="btn btn-default hm" onClick={() => { this.update_code('') }}></button>
                                                    </td>
                                                    <td></td>
                                                    <td>
                                                        <button title="hamextfingerdr" className="btn btn-default hm" onClick={() => { this.update_code('') }}></button>
                                                    </td>
                                                    <td></td>

                                                </tr>
                                                <tr>
                                                    <td></td>
                                                    <td></td>
                                                    <td>
                                                        <button title="hamextfingerd" className="btn btn-default hm" onClick={() => { this.update_code('') }}></button>
                                                    </td>
                                                    <td></td>
                                                    <td></td>

                                                </tr>
                                            </tbody>
                                        </table>
                                    </Grid>
                                    <Grid item >

                                        <table>
                                            <tbody>

                                                <tr>
                                                    <td></td>
                                                    <td></td>
                                                    <td>
                                                        <button title="hamextfingero" className="btn btn-default hm" onClick={() => { this.update_code('') }}></button>

                                                    </td>
                                                    <td></td>
                                                    <td></td>

                                                </tr>
                                                <tr>
                                                    <td></td>
                                                    <td>
                                                        <button title="hamextfingerol" className="btn btn-default hm" onClick={() => { this.update_code('') }}></button>
                                                    </td>
                                                    <td></td>
                                                    <td>
                                                        <button title="hamextfingeror" className="btn btn-default hm" onClick={() => { this.update_code('') }}></button>
                                                    </td>
                                                    <td></td>

                                                </tr>
                                                <tr>
                                                    <td>
                                                        <button title="hamextfingerl" className="btn btn-default hm" onClick={() => { this.update_code('') }}></button>
                                                    </td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td>
                                                        <button title="hamextfingerr" className="btn btn-default hm" onClick={() => { this.update_code('') }}></button>
                                                    </td>

                                                </tr>
                                                <tr>
                                                    <td></td>
                                                    <td>
                                                        <button title="hamextfingeril" className="btn btn-default hm" onClick={() => { this.update_code('') }}></button>
                                                    </td>
                                                    <td></td>
                                                    <td>
                                                        <button title="hamextfingerir" className="btn btn-default hm" onClick={() => { this.update_code('') }}></button>

                                                    </td>
                                                    <td></td>

                                                </tr>
                                                <tr>
                                                    <td></td>
                                                    <td></td>
                                                    <td>
                                                        <button title="hamextfingeri" className="btn btn-default hm" onClick={() => { this.update_code('') }}></button>
                                                    </td>
                                                    <td></td>
                                                    <td></td>

                                                </tr>
                                            </tbody>
                                        </table>
                                    </Grid>
                                </Grid>
                            </div>
                            <div>
                                <Grid container justify="center" spacing={2}>

                                    <Grid item >


                                        <table>
                                            <tbody>

                                                <tr>
                                                    <td></td>
                                                    <td></td>
                                                    <td>
                                                        <button title="hamextfingeru" className="btn btn-default hm" onClick={() => { this.update_code('') }}></button>

                                                    </td>
                                                    <td></td>
                                                    <td></td>

                                                </tr>
                                                <tr>
                                                    <td></td>
                                                    <td>
                                                        <button title="hamextfingerui" className="btn btn-default hm" onClick={() => { this.update_code('') }}></button>
                                                    </td>
                                                    <td></td>
                                                    <td>
                                                        <button title="hamextfingeruo" className="btn btn-default hm" onClick={() => { this.update_code('') }}></button>
                                                    </td>
                                                    <td></td>

                                                </tr>
                                                <tr>
                                                    <td>
                                                        <button title="hamextfingeri" className="btn btn-default hm" onClick={() => { this.update_code('') }}></button>
                                                    </td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td>
                                                        <button title="hamextfingero" className="btn btn-default hm" onClick={() => { this.update_code('') }}></button>
                                                    </td>

                                                </tr>
                                                <tr>
                                                    <td></td>
                                                    <td>
                                                        <button title="hamextfingerdi" className="btn btn-default hm" onClick={() => { this.update_code('') }}></button>
                                                    </td>
                                                    <td></td>
                                                    <td>
                                                        <button title="hamextfingerdo" className="btn btn-default hm" onClick={() => { this.update_code('') }}></button>
                                                    </td>
                                                    <td></td>

                                                </tr>
                                                <tr>
                                                    <td></td>
                                                    <td></td>
                                                    <td>
                                                        <button title="hamextfingerd" className="btn btn-default hm" onClick={() => { this.update_code('') }}></button>
                                                    </td>
                                                    <td></td>
                                                    <td></td>

                                                </tr>
                                            </tbody>
                                        </table>
                                    </Grid>
                                    <Grid item >

                                        <table>
                                            <tbody>

                                                <tr>
                                                    <td></td>
                                                    <td></td>
                                                    <td>
                                                        <button title="hampalmu" className="btn btn-default hm" onClick={() => { this.update_code('') }}></button>
                                                    </td>
                                                    <td></td>
                                                    <td></td>
                                                </tr>
                                                <tr>
                                                    <td></td>
                                                    <td>
                                                        <button title="hampalmul" className="btn btn-default hm" onClick={() => { this.update_code('') }}></button>
                                                    </td>
                                                    <td></td>
                                                    <td>
                                                        <button title="hampalmur" className="btn btn-default hm" onClick={() => { this.update_code('') }}></button>
                                                    </td>
                                                    <td></td>

                                                </tr>
                                                <tr>
                                                    <td>
                                                        <button title="hampalml" className="btn btn-default hm" onClick={() => { this.update_code('') }}></button>
                                                    </td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td>
                                                        <button title="hampalmr" className="btn btn-default hm" onClick={() => { this.update_code('') }}></button>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td></td>
                                                    <td>
                                                        <button title="hampalmdl" className="btn btn-default hm" onClick={() => { this.update_code('') }}></button>
                                                    </td>
                                                    <td></td>
                                                    <td>
                                                        <button title="hampalmdr" className="btn btn-default hm" onClick={() => { this.update_code('') }}></button>
                                                    </td>
                                                    <td></td>
                                                </tr>
                                                <tr>
                                                    <td></td>
                                                    <td></td>
                                                    <td>
                                                        <button title="hampalmd" className="btn btn-default hm" onClick={() => { this.update_code('') }}></button>
                                                    </td>
                                                    <td></td>
                                                    <td></td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </Grid>
                                </Grid>
                            </div>
                        </Carousel>
                        <Grid container justify="center">
                            <Grid item>
                                <Paper variant="outlined">

                                    <button title="hambetween" className="btn btn-default hm" onClick={() => { this.update_code('') }}></button>

                                    <button title="hamorirelative" className="btn btn-default hm" onClick={() => { this.update_code('') }}></button>
                                </Paper>

                            </Grid>
                        </Grid>

                    </TabPane>
                    <TabPane tab="Locations" key="3">
                        <button title="hamheadtop" className="btn btn-default hm" value="" onClick={() => { this.update_code('') }}></button>
                        <button title="hamhead" className="btn btn-default hm" value="" onClick={() => { this.update_code('') }}></button>
                        <button title="hamforehead" className="btn btn-default hm" value="" onClick={() => { this.update_code('') }}></button>
                        <button title="hamnose" className="btn btn-default hm" value="" onClick={() => { this.update_code('') }}></button>
                        <button title="hamnostrils" className="btn btn-default hm" value="" onClick={() => { this.update_code('') }}></button>
                        <button title="hamlips" className="btn btn-default hm" value="" onClick={() => { this.update_code('') }}></button>
                        <button title="hamtongue" className="btn btn-default hm" value="" onClick={() => { this.update_code('') }}></button>
                        <button title="hamteeth" className="btn btn-default hm" value="" onClick={() => { this.update_code('') }}></button>
                        <button title="hamchin" className="btn btn-default hm" value="" onClick={() => { this.update_code('') }}></button>
                        <button title="hamunderchin" className="btn btn-default hm" value="" onClick={() => { this.update_code('') }}></button>
                        <button title="hamneck" className="btn btn-default hm" value="" onClick={() => { this.update_code('') }}></button>
                        <button title="hamshouldertop" className="btn btn-default hm" onClick={() => { this.update_code('') }}></button>
                        <button title="hamshoulders" className="btn btn-default hm" onClick={() => { this.update_code('') }}></button>
                        <button title="hamchest" className="btn btn-default hm" onClick={() => { this.update_code('') }}></button>
                        <button title="hamstomach" className="btn btn-default hm" onClick={() => { this.update_code('') }}></button>
                        <button title="hambelowstomach" className="btn btn-default hm" onClick={() => { this.update_code('') }}></button>
                        <button title="hameyebrows" className="btn btn-default hm" onClick={() => { this.update_code('') }}></button>
                        <button title="hameyes" className="btn btn-default hm" onClick={() => { this.update_code('') }}></button>
                        <button title="hamear" className="btn btn-default hm" onClick={() => { this.update_code('') }}></button>
                        <button title="hamearlobe" className="btn btn-default hm" onClick={() => { this.update_code('') }}></button>
                        <button title="hamcheek" className="btn btn-default hm" onClick={() => { this.update_code('') }}></button>
                        <button title="hamupperarm" className="btn btn-default hm" onClick={() => { this.update_code('') }}></button>
                        <button title="hamelbowinside" className="btn btn-default hm" onClick={() => { this.update_code('') }}></button>
                        <button title="hamlowerarm" className="btn btn-default hm" onClick={() => { this.update_code('') }}></button>
                        <button title="hamwristback" className="btn btn-default hm" onClick={() => { this.update_code('') }}></button>
                        <button title="hamthumbball" className="btn btn-default hm" onClick={() => { this.update_code('') }}></button>
                        <button title="hampalm" className="btn btn-default hm" onClick={() => { this.update_code('') }}></button>
                        <button title="hamhandback" className="btn btn-default hm" onClick={() => { this.update_code('') }}></button>
                        <button title="hamthumbside" className="btn btn-default hm" onClick={() => { this.update_code('') }}></button>
                        <button title="hampinkyside" className="btn btn-default hm" onClick={() => { this.update_code('') }}></button>
                        <button title="hamthumb" className="btn btn-default hm" onClick={() => { this.update_code('') }}></button>
                        <button title="hamindexfinger" className="btn btn-default hm" onClick={() => { this.update_code('') }}></button>
                        <button title="hammiddlefinger" className="btn btn-default hm" onClick={() => { this.update_code('') }}></button>
                        <button title="hamringfinger" className="btn btn-default hm" onClick={() => { this.update_code('') }}></button>
                        <button title="hampinky" className="btn btn-default hm" onClick={() => { this.update_code('') }}></button>
                        <button title="hambetween" className="btn btn-default hm" onClick={() => { this.update_code('') }}></button>
                        <button title="hamfingertip" className="btn btn-default hm" onClick={() => { this.update_code('') }}></button>
                        <button title="hamfingernail" className="btn btn-default hm" onClick={() => { this.update_code('') }}></button>
                        <button title="hamfingerpad" className="btn btn-default hm" onClick={() => { this.update_code('') }}></button>
                        <button title="hamfingermidjoint" className="btn btn-default hm" onClick={() => { this.update_code('') }}></button>
                        <button title="hamfingerbase" className="btn btn-default hm" onClick={() => { this.update_code('') }}></button>
                        <button title="hamclose" className="btn btn-default hm" onClick={() => { this.update_code('') }}></button>
                        <button title="hamtouch" className="btn btn-default hm" onClick={() => { this.update_code('') }}></button>
                        <button title="haminterlock" className="btn btn-default hm" onClick={() => { this.update_code('') }}></button>
                        <button title="hamcross" className="btn btn-default hm" onClick={() => { this.update_code('') }}></button>
                        <button title="hambrushing" className="btn btn-default hm" onClick={() => { this.update_code('') }}></button>
                        <button title="hambehind" className="btn btn-default hm" onClick={() => { this.update_code('') }}></button>
                        <button title="hamarmextended" className="btn btn-default hm" onClick={() => { this.update_code('') }}></button>
                        <button title="hamlrbeside" className="btn btn-default hm" onClick={() => { this.update_code('') }}></button>
                        <button title="hamlrat" className="btn btn-default hm" onClick={() => { this.update_code('') }}></button>
                        <button title="hamdoublebent" className="btn btn-default hm" onClick={() => { this.update_code('') }}></button>
                        <button title="hamdoublehooked" className="btn btn-default hm" onClick={() => { this.update_code('') }}></button>
                        <button title="hamneutralspace" className="btn btn-default hm" onClick={() => { this.update_code('') }}></button>

                    </TabPane>
                    <TabPane tab="Movement-1" key="4">
                        <Carousel>
                            <div>
                                <Grid container justify="center" spacing={2}>
                                    <Grid item>

                                        <table>
                                            <tbody>

                                                <tr>
                                                    <td></td>
                                                    <td></td>
                                                    <td>
                                                        <button title="hammoveu" className="btn btn-default hm" onClick={() => { this.update_code('') }}></button>

                                                    </td>
                                                    <td></td>
                                                    <td></td>

                                                </tr>
                                                <tr>
                                                    <td></td>
                                                    <td>
                                                        <button title="hammoveul" className="btn btn-default hm" onClick={() => { this.update_code('') }}></button>

                                                    </td>
                                                    <td></td>
                                                    <td>
                                                        <button title="hammoveur" className="btn btn-default hm" onClick={() => { this.update_code('') }}></button>
                                                    </td>
                                                    <td></td>

                                                </tr>
                                                <tr>
                                                    <td>
                                                        <button title="hammovel" className="btn btn-default hm" onClick={() => { this.update_code('') }}></button>
                                                    </td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td>
                                                        <button title="hammover" className="btn btn-default hm" onClick={() => { this.update_code('') }}></button>
                                                    </td>

                                                </tr>
                                                <tr>
                                                    <td></td>
                                                    <td>
                                                        <button title="hammovedl" className="btn btn-default hm" onClick={() => { this.update_code('') }}></button>

                                                    </td>
                                                    <td></td>
                                                    <td>
                                                        <button title="hammovedr" className="btn btn-default hm" onClick={() => { this.update_code('') }}></button>
                                                    </td>
                                                    <td></td>

                                                </tr>
                                                <tr>
                                                    <td></td>
                                                    <td></td>
                                                    <td>
                                                        <button title="hammoved" className="btn btn-default hm" onClick={() => { this.update_code('') }}></button>
                                                    </td>
                                                    <td></td>
                                                    <td></td>

                                                </tr>
                                            </tbody>
                                        </table>
                                    </Grid>
                                    <Grid item>
                                        <table>
                                            <tbody>

                                                <tr>
                                                    <td></td>
                                                    <td></td>
                                                    <td>
                                                        <button title="hammoveo" className="btn btn-default hm" onClick={() => { this.update_code('') }}></button>
                                                    </td>
                                                    <td></td>
                                                    <td></td>

                                                </tr>
                                                <tr>
                                                    <td></td>
                                                    <td>
                                                        <button title="hammoveol" className="btn btn-default hm" onClick={() => { this.update_code('') }}></button>
                                                    </td>
                                                    <td></td>
                                                    <td>
                                                        <button title="hammoveor" className="btn btn-default hm" onClick={() => { this.update_code('') }}></button>
                                                    </td>
                                                    <td></td>

                                                </tr>
                                                <tr>
                                                    <td>
                                                        <button title="hammovel" className="btn btn-default hm" onClick={() => { this.update_code('') }}></button>
                                                    </td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td>
                                                        <button title="hammover" className="btn btn-default hm" onClick={() => { this.update_code('') }}></button>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td></td>
                                                    <td>
                                                        <button title="hammoveil" className="btn btn-default hm" onClick={() => { this.update_code('') }}></button>

                                                    </td>
                                                    <td></td>
                                                    <td>
                                                        <button title="hammoveir" className="btn btn-default hm" onClick={() => { this.update_code('') }}></button>

                                                    </td>
                                                    <td></td>

                                                </tr>
                                                <tr>
                                                    <td></td>
                                                    <td></td>
                                                    <td>
                                                        <button title="hammovei" className="btn btn-default hm" onClick={() => { this.update_code('') }}></button>
                                                    </td>
                                                    <td></td>
                                                    <td></td>

                                                </tr>
                                            </tbody>
                                        </table>

                                    </Grid>
                                </Grid>
                              </div>
                              <div>
                                <Grid container justify="center" spacing={2}>
                                    <Grid item>
                                        <table>
                                            <tbody>

                                                <tr>
                                                    <td></td>
                                                    <td></td>
                                                    <td>
                                                        <button title="hammoveu" className="btn btn-default hm" onClick={() => { this.update_code('') }}></button>
                                                    </td>
                                                    <td></td>
                                                    <td></td>

                                                </tr>
                                                <tr>
                                                    <td></td>
                                                    <td>
                                                        <button title="hammoveui" className="btn btn-default hm" onClick={() => { this.update_code('') }}></button>
                                                    </td>
                                                    <td></td>
                                                    <td>
                                                        <button title="hammoveuo" className="btn btn-default hm" onClick={() => { this.update_code('') }}></button>
                                                    </td>
                                                    <td></td>

                                                </tr>
                                                <tr>
                                                    <td>
                                                        <button title="hammovei" className="btn btn-default hm" onClick={() => { this.update_code('') }}></button>

                                                    </td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td>

                                                        <button title="hammoveo" className="btn btn-default hm" onClick={() => { this.update_code('') }}></button>
                                                    </td>

                                                </tr>
                                                <tr>
                                                    <td></td>
                                                    <td>

                                                        <button title="hammovedi" className="btn btn-default hm" onClick={() => { this.update_code('') }}></button>
                                                    </td>
                                                    <td></td>
                                                    <td>
                                                        <button title="hammovedo" className="btn btn-default hm" onClick={() => { this.update_code('') }}></button>
                                                    </td>
                                                    <td></td>

                                                </tr>
                                                <tr>
                                                    <td></td>
                                                    <td></td>
                                                    <td>
                                                        <button title="hammoved" className="btn btn-default hm" onClick={() => { this.update_code('') }}></button>
                                                    </td>
                                                    <td></td>
                                                    <td></td>

                                                </tr>
                                            </tbody>
                                        </table>
                                    </Grid>
                                    <Grid item>
                                        <table>
                                            <tbody>

                                                <tr>

                                                    <td>
                                                        <button title="hamfast" className="btn btn-default hm" onClick={() => { this.update_code('') }}></button>
                                                    </td>
                                                    <td>
                                                        <button title="hamslow" className="btn btn-default hm" onClick={() => { this.update_code('') }}></button>
                                                    </td>
                                                    <td>
                                                        <button title="hamtense" className="btn btn-default hm" onClick={() => { this.update_code('') }}></button>
                                                    </td>
                                                    <td>
                                                        <button title="hamrest" className="btn btn-default hm" onClick={() => { this.update_code('') }}></button>
                                                    </td>
                                                    <td>
                                                        <button title="hamhalt" className="btn btn-default hm" onClick={() => { this.update_code('') }}></button>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <button title="hamrepeatfromstart" className="btn btn-default hm" onClick={() => { this.update_code('') }}></button>
                                                    </td>
                                                    <td>
                                                        <button title="hamrepeatfromstartseveral" className="btn btn-default hm" onClick={() => { this.update_code('') }}></button>
                                                    </td>
                                                    <td>
                                                        <button title="hamrepeatcontinue" className="btn btn-default hm" onClick={() => { this.update_code('') }}></button>
                                                    </td>
                                                    <td>
                                                        <button title="hamrepeatcontinueseveral" className="btn btn-default hm" onClick={() => { this.update_code('') }}></button>
                                                    </td>
                                                    <td>
                                                        <button title="hamrepeatreverse" className="btn btn-default hm" onClick={() => { this.update_code('') }}></button>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <button title="hamsmallmod" className="btn btn-default hm" onClick={() => { this.update_code('') }}>&nbsp;</button>

                                                    </td>
                                                    <td>
                                                        <button title="hamlargemod" className="btn btn-default hm" onClick={() => { this.update_code('') }}>&nbsp;</button>

                                                    </td>
                                                    <td>
                                                        <button title="hamnomotion" className="btn btn-default hm" onClick={() => { this.update_code('') }}></button>

                                                    </td>
                                                    <td>
                                                        <button title="hamincreasing" className="btn btn-default hm" onClick={() => { this.update_code('') }}></button>
                                                    </td>
                                                    <td>
                                                        <button title="hamdecreasing" className="btn btn-default hm" onClick={() => { this.update_code('') }}></button>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <button title="hamarcl" className="btn btn-default hm" onClick={() => { this.update_code('') }}></button>
                                                    </td>
                                                    <td>
                                                        <button title="hamarcu" className="btn btn-default hm" onClick={() => { this.update_code('') }}></button>
                                                    </td>
                                                    <td>
                                                        <button title="hamarcd" className="btn btn-default hm" onClick={() => { this.update_code('') }}></button>
                                                    </td>
                                                    <td>
                                                        <button title="hamarcr" className="btn btn-default hm" onClick={() => { this.update_code('') }}></button>
                                                    </td>
                                                    <td>
                                                        <button title="hamwavy" className="btn btn-default hm" onClick={() => { this.update_code('') }}></button>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <button title="hamellipsev" className="btn btn-default hm" onClick={() => { this.update_code('') }}></button>
                                                    </td>
                                                    <td>
                                                        <button title="hamellipseur" className="btn btn-default hm" onClick={() => { this.update_code('') }}></button>
                                                    </td>
                                                    <td>
                                                        <button title="hamellipseh" className="btn btn-default hm" onClick={() => { this.update_code('') }}></button>
                                                    </td>
                                                    <td>
                                                        <button title="hamellipseul" className="btn btn-default hm" onClick={() => { this.update_code('') }}></button>
                                                    </td>
                                                    <td>
                                                        <button title="hamzigzag" className="btn btn-default hm" onClick={() => { this.update_code('') }}></button>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </Grid>
                                </Grid>
                            </div>
                        </Carousel>
                        <div className="row">
                            <div className="col">
                                <button title="hambetween" className="btn btn-default hm" onClick={() => { this.update_code('') }}></button>
                                <button title="hamspace" className="btn btn-default hm" onClick={() => { this.update_code(' ') }}> </button>
                            </div>
                        </div>
                    </TabPane>
                    <TabPane tab="Movement-2" key="5">
                        <Carousel>
                            <div>
                                <Grid container justify="center" spacing={2}>
                                    <Grid item>


                                        <table>
                                            <tbody>

                                                <tr>
                                                    <td></td>
                                                    <td></td>
                                                    <td>
                                                        <button title="hamcircleu" className="btn btn-default hm" onClick={() => { this.update_code('') }}></button>
                                                    </td>
                                                    <td></td>
                                                    <td></td>

                                                </tr>
                                                <tr>
                                                    <td></td>
                                                    <td>
                                                        <button title="hamcircleul" className="btn btn-default hm" onClick={() => { this.update_code('') }}></button>
                                                    </td>
                                                    <td></td>
                                                    <td>
                                                        <button title="hamcircleur" className="btn btn-default hm" onClick={() => { this.update_code('') }}></button>
                                                    </td>
                                                    <td></td>

                                                </tr>
                                                <tr>
                                                    <td>
                                                        <button title="hamcirclel" className="btn btn-default hm" onClick={() => { this.update_code('') }}></button>
                                                    </td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td>
                                                        <button title="hamcircler" className="btn btn-default hm" onClick={() => { this.update_code('') }}></button>
                                                    </td>

                                                </tr>
                                                <tr>
                                                    <td></td>
                                                    <td>
                                                        <button title="hamcircledl" className="btn btn-default hm" onClick={() => { this.update_code('') }}></button>
                                                    </td>
                                                    <td></td>
                                                    <td>
                                                        <button title="hamcircledr" className="btn btn-default hm" onClick={() => { this.update_code('') }}></button>
                                                    </td>
                                                    <td></td>

                                                </tr>
                                                <tr>
                                                    <td></td>
                                                    <td></td>
                                                    <td>
                                                        <button title="hamcircled" className="btn btn-default hm" onClick={() => { this.update_code('') }}></button>
                                                    </td>
                                                    <td></td>
                                                    <td></td>

                                                </tr>
                                            </tbody>
                                        </table>
                                    </Grid>
                                    <Grid item>

                                        <table>
                                            <tbody>

                                                <tr>
                                                    <td></td>
                                                    <td></td>
                                                    <td>
                                                        <button title="hamcircleo" className="btn btn-default hm" onClick={() => { this.update_code('') }}></button>
                                                    </td>
                                                    <td></td>
                                                    <td></td>

                                                </tr>
                                                <tr>
                                                    <td></td>
                                                    <td>
                                                        <button title="hamcircleol" className="btn btn-default hm" onClick={() => { this.update_code('') }}></button>
                                                    </td>
                                                    <td></td>
                                                    <td>
                                                        <button title="hamcircleor" className="btn btn-default hm" onClick={() => { this.update_code('') }}></button>
                                                    </td>
                                                    <td></td>

                                                </tr>
                                                <tr>
                                                    <td>
                                                        <button title="hamcirclel" className="btn btn-default hm" onClick={() => { this.update_code('') }}></button>
                                                    </td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td>
                                                        <button title="hamcircler" className="btn btn-default hm" onClick={() => { this.update_code('') }}></button>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td></td>
                                                    <td>
                                                        <button title="hamcircleil" className="btn btn-default hm" onClick={() => { this.update_code('') }}></button>

                                                    </td>
                                                    <td></td>
                                                    <td>
                                                        <button title="hamcircleir" className="btn btn-default hm" onClick={() => { this.update_code('') }}></button>


                                                    </td>
                                                    <td></td>

                                                </tr>
                                                <tr>
                                                    <td></td>
                                                    <td></td>
                                                    <td>
                                                        <button title="hamcirclei" className="btn btn-default hm" onClick={() => { this.update_code('') }}></button>

                                                    </td>
                                                    <td></td>
                                                    <td></td>

                                                </tr>
                                            </tbody>
                                        </table>
                                    </Grid>
                                </Grid>
                            </div>
                            <div>
                                <Grid container justify="center" spacing={2}>
                                    <Grid item>

                                        <table>
                                            <tbody>

                                                <tr>
                                                    <td></td>
                                                    <td></td>
                                                    <td>
                                                        <button title="hamcircleu" className="btn btn-default hm" onClick={() => { this.update_code('') }}></button>
                                                    </td>
                                                    <td></td>
                                                    <td></td>

                                                </tr>
                                                <tr>
                                                    <td></td>
                                                    <td>
                                                        <button title="hamcircleui" className="btn btn-default hm" onClick={() => { this.update_code('') }}></button>
                                                    </td>
                                                    <td></td>
                                                    <td>
                                                        <button title="hamcircleuo" className="btn btn-default hm" onClick={() => { this.update_code('') }}></button>
                                                    </td>
                                                    <td></td>

                                                </tr>
                                                <tr>
                                                    <td>
                                                        <button title="hamcirclei" className="btn btn-default hm" onClick={() => { this.update_code('') }}></button>
                                                    </td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td>
                                                        <button title="hamcircleo" className="btn btn-default hm" onClick={() => { this.update_code('') }}></button>
                                                    </td>

                                                </tr>
                                                <tr>
                                                    <td></td>
                                                    <td>
                                                        <button title="hamcircledi" className="btn btn-default hm" onClick={() => { this.update_code('') }}></button>
                                                    </td>
                                                    <td></td>
                                                    <td>
                                                        <button title="hamcircledo" className="btn btn-default hm" onClick={() => { this.update_code('') }}></button>
                                                    </td>
                                                    <td></td>

                                                </tr>
                                                <tr>
                                                    <td></td>
                                                    <td></td>
                                                    <td>
                                                        <button title="hamcircled" className="btn btn-default hm" onClick={() => { this.update_code('') }}></button>
                                                    </td>
                                                    <td></td>
                                                    <td></td>

                                                </tr>
                                            </tbody>
                                        </table>

                                    </Grid>
                                    <Grid item>

                                        <table>
                                            <tbody>

                                                <tr>
                                                    <td></td>
                                                    <td></td>
                                                    <td>
                                                        <button title="hamclocku" className="btn btn-default hm" onClick={() => { this.update_code('') }}></button>
                                                    </td>
                                                    <td></td>
                                                    <td></td>

                                                </tr>
                                                <tr>
                                                    <td></td>
                                                    <td>
                                                        <button title="hamclockul" className="btn btn-default hm" onClick={() => { this.update_code('') }}></button>
                                                    </td>
                                                    <td></td>
                                                    <td>
                                                        <button title="hamclockur" className="btn btn-default hm" onClick={() => { this.update_code('') }}></button>
                                                    </td>
                                                    <td></td>

                                                </tr>
                                                <tr>
                                                    <td>
                                                        <button title="hamclockl" className="btn btn-default hm" onClick={() => { this.update_code('') }}></button>

                                                    </td>
                                                    <td></td>
                                                    <td>
                                                        <button title="hamclockfull" className="btn btn-default hm" onClick={() => { this.update_code('') }}></button>

                                                    </td>
                                                    <td></td>
                                                    <td>
                                                        <button title="hamclockr" className="btn btn-default hm" onClick={() => { this.update_code('') }}></button>

                                                    </td>

                                                </tr>
                                                <tr>
                                                    <td></td>
                                                    <td>
                                                        <button title="hamclockdl" className="btn btn-default hm" onClick={() => { this.update_code('') }}></button>
                                                    </td>
                                                    <td></td>
                                                    <td>
                                                        <button title="hamclockdr" className="btn btn-default hm" onClick={() => { this.update_code('') }}></button>
                                                    </td>
                                                    <td></td>

                                                </tr>
                                                <tr>
                                                    <td></td>
                                                    <td></td>
                                                    <td>
                                                        <button title="hamclockd" className="btn btn-default hm" onClick={() => { this.update_code('') }}></button>
                                                    </td>
                                                    <td></td>
                                                    <td></td>

                                                </tr>
                                            </tbody>
                                        </table>

                                    </Grid>
                                </Grid>

                            </div>

                           
                        </Carousel>
                        <div className="row">
                                <div className="col-md-12">
                                    <button title="hamstircw" className="btn btn-default hm" onClick={() => { this.update_code('') }}></button>
                                    <button title="hamnodding" className="btn btn-default hm" onClick={() => { this.update_code('') }}></button>
                                    <button title="hamtwisting" className="btn btn-default hm" onClick={() => { this.update_code('') }}></button>
                                    <button title="hamreplace" className="btn btn-default hm" onClick={() => { this.update_code('') }}></button>

                             


                                    <button title="hamstirccw" className="btn btn-default hm" onClick={() => { this.update_code('') }}></button>
                                    <button title="hamswinging" className="btn btn-default hm" onClick={() => { this.update_code('') }}></button>
                                    <button title="hamfingerplay" className="btn btn-default hm" onClick={() => { this.update_code('') }}></button>
                                    <button title="hambetween" className="btn btn-default hm" onClick={() => { this.update_code('') }}></button>
                                </div>
                            </div>
                    </TabPane>
                    <TabPane tab="Two Handed" key="6">
                        <button title="hamsymmpar" className="btn btn-default hm" onClick={() => { this.update_code('') }}></button>
                        <button title="hamsymmlr" className="btn btn-default hm" onClick={() => { this.update_code('') }}></button>
                        <button title="hamfingerstraightmod" className="btn btn-default hm" onClick={() => { this.update_code('') }}></button>
                        <button title="hamlargemod" className="btn btn-default hm" onClick={() => { this.update_code('') }}></button>
                        <button title="hamsymmlr,hamfingerstraightmod,hamlargemod" className="btn btn-default hm" onClick={() => { this.update_code('') }}></button>
                        <button title="hamparbegin" className="btn btn-default hm" onClick={() => { this.update_code('') }}></button>
                        <button title="hamplus" className="btn btn-default hm" onClick={() => { this.update_code('') }}></button>
                        <button title="hamparend" className="btn btn-default hm" onClick={() => { this.update_code('') }}></button>
                        <button title="hamalternatingmotion" className="btn btn-default hm" onClick={() => { this.update_code('') }}></button>
                        <button title="hamnonipsi" className="btn btn-default hm" onClick={() => { this.update_code('') }}></button>
                        <button title="hamnondominant" className="btn btn-default hm" onClick={() => { this.update_code('') }}></button>
                        <button title="hametc" className="btn btn-default hm" onClick={() => { this.update_code('') }}></button>
                        <button title="hamseqbegin" className="btn btn-default hm" onClick={() => { this.update_code('') }}></button>
                        <button title="hamseqend" className="btn btn-default hm" onClick={() => { this.update_code('') }}></button>
                        <button title="hamparbegin" className="btn btn-default hm" onClick={() => { this.update_code('') }}></button>
                        <button title="hamparend" className="btn btn-default hm" onClick={() => { this.update_code('') }}></button>
                        <button title="hamfusionbegin" className="btn btn-default hm" onClick={() => { this.update_code('') }}></button>
                        <button title="hamfusionend" className="btn btn-default hm" onClick={() => { this.update_code('') }}></button>
                        <button title="hamaltbegin" className="btn btn-default hm" onClick={() => { this.update_code('{') }}>{'{'}</button>
                        <button title="hamaltend" className="btn btn-default hm" onClick={() => { this.update_code('}') }}>{'}'}</button>
                        <button title="hammetaalt" className="btn btn-default hm" onClick={() => { this.update_code('|') }}>|</button>
                        <button title="hamcoreftag" className="btn btn-default hm" onClick={() => { this.update_code('') }}></button>
                        <button title="hamcorefref" className="btn btn-default hm" onClick={() => { this.update_code('') }}></button>
                        <button title="hamthumb" className="btn btn-default hm" onClick={() => { this.update_code('') }}></button>
                        <button title="hamindexfinger" className="btn btn-default hm" onClick={() => { this.update_code('') }}></button>
                        <button title="hammiddlefinger" className="btn btn-default hm" onClick={() => { this.update_code('') }}></button>
                        <button title="hamringfinger" className="btn btn-default hm" onClick={() => { this.update_code('') }}></button>
                        <button title="hampinky" className="btn btn-default hm" onClick={() => { this.update_code('') }}></button>
                        <button title="hamcomma" className="btn btn-default hm" onClick={() => { this.update_code(',') }}>,</button>
                        <button title="hamfullstop" className="btn btn-default hm" onClick={() => { this.update_code('.') }}>.</button>
                        <button title="hamexclaim" className="btn btn-default hm" onClick={() => { this.update_code('!') }}>!</button>
                        <button title="hamquery" className="btn btn-default hm" onClick={() => { this.update_code('?') }}>?</button>
                        <button title="hammime" className="btn btn-default hm" onClick={() => { this.update_code('') }}></button>

                    </TabPane>
                    <TabPane tab="Non Manual" key="7">
                        <table>
                            <tbody>
                                <tr>
                                    <td onClick={() => { this.setState({ hnm_mouthgesture: '' }) }}> Mouth : </td>
                                    <td>
                                        <FormControl className={classes.formControl}>
                                            <InputLabel >Teeth</InputLabel>
                                            <Select
                                                value={this.state.hnm_mouthgesture}
                                                onChange={(e) => { this.update_code_mouth_gesture(e.target.value) }}
                                            >
                                                {Array(9).fill(1).map((el, i) =>
                                                    <MenuItem key={i} value={"D0" + (i + 1)}>D0{i + 1}</MenuItem>

                                                )}
                                            </Select>
                                        </FormControl>
                                    </td>

                                    <td>
                                        <FormControl className={classes.formControl}>
                                            <InputLabel >Jaws</InputLabel>
                                            <Select
                                                value={this.state.hnm_mouthgesture}
                                                onChange={(e) => { this.update_code_mouth_gesture(e.target.value) }}
                                            >
                                                {Array(4).fill(1).map((el, i) =>
                                                    <MenuItem key={i} value={"J0" + (i + 1)}>J0{i + 1}</MenuItem>

                                                )}
                                            </Select>
                                        </FormControl>
                                    </td>
                                    <td>
                                        <FormControl className={classes.formControl}>
                                            <InputLabel >Lips</InputLabel>
                                            <Select
                                                value={this.state.hnm_mouthgesture}
                                                onChange={(e) => { this.update_code_mouth_gesture(e.target.value) }}
                                            >
                                                {Array(35).fill(1).map((el, i) =>
                                                    <MenuItem key={i} value={"L" + this.convertDigits(i + 1)}>L{this.convertDigits(i + 1)}</MenuItem>

                                                )}
                                            </Select>
                                        </FormControl>
                                    </td>
                                    <td>
                                        <FormControl className={classes.formControl}>
                                            <InputLabel >Cheeks</InputLabel>
                                            <Select
                                                value={this.state.hnm_mouthgesture}
                                                onChange={(e) => { this.update_code_mouth_gesture(e.target.value) }}
                                            >
                                                {Array(13).fill(1).map((el, i) =>
                                                    <MenuItem key={i} value={"C" + this.convertDigits(i + 1)}>C{this.convertDigits(i + 1)}</MenuItem>

                                                )}
                                            </Select>
                                        </FormControl>
                                    </td>
                                    <td>
                                        <FormControl className={classes.formControl}>
                                            <InputLabel >Tongue</InputLabel>
                                            <Select
                                                value={this.state.hnm_mouthgesture}
                                                onChange={(e) => { this.update_code_mouth_gesture(e.target.value) }}
                                            >
                                                {Array(17).fill(1).map((el, i) =>
                                                    <MenuItem key={i} value={"T" + this.convertDigits(i + 1)}>T{this.convertDigits(i + 1)}</MenuItem>

                                                )}
                                            </Select>
                                        </FormControl>
                                    </td>
                                </tr>

                            </tbody>
                        </table>
                        <table id="nm-table">
                            <tbody>

                                <tr>
                                    <td onClick={() => { this.setState({ hnm_body: '' }) }}> Body : </td>
                                    <td><button title="Rotate Left" className="btn btn-default nm-hm" onClick={() => { this.update_code_body("RL") }} >RL</button></td>
                                    <td><button title="Rotate Right" className="btn btn-default nm-hm" onClick={() => { this.update_code_body("RR") }} >RR</button></td>
                                    <td><button title="Tilted Left" className="btn btn-default nm-hm" onClick={() => { this.update_code_body("TL") }} >TL</button></td>
                                    <td><button title="Tilted Right" className="btn btn-default nm-hm" onClick={() => { this.update_code_body("TR") }} >TR</button></td>
                                    <td><button title="Tilted Forward(Leaning Forward)" className="btn btn-default nm-hm" onClick={() => { this.update_code_body("TF") }} >TF</button></td>
                                    <td><button title="Tilted Backward(Leaning Backward)" className="btn btn-default nm-hm" onClick={() => { this.update_code_body("TB") }} >TB</button></td>
                                    <td><button title="Sigh-Dynamic(Deep-in and Exhalation visibaly moving chest)" className="btn btn-default nm-hm" onClick={() => { this.update_code_body("SI") }} >SI</button></td>
                                    <td><button title="Heave(Chest move upward)" className="btn btn-default nm-hm" onClick={() => { this.update_code_body("HE") }} >HE</button></td>
                                    <td><button title="Straight(Back upright)" className="btn btn-default nm-hm" onClick={() => { this.update_code_body("ST") }} >ST</button></td>
                                    <td><button title="Round(Back Rounded)" className="btn btn-default nm-hm" onClick={() => { this.update_code_body("RD") }} >RD</button></td>

                                </tr>
                                <tr>
                                    <td onClick={() => { this.setState({ hnm_shoulder: '' }) }}> Shoulder : </td>
                                    <td><button title="Left Shoulder Raised" className="btn btn-default nm-hm" onClick={() => { this.update_code_shoulder("UL") }} >UL</button></td>
                                    <td><button title="Right Shoulder Raised" className="btn btn-default nm-hm" onClick={() => { this.update_code_shoulder("UR") }} >UR</button></td>
                                    <td><button title="Both Shoulder Raised" className="btn btn-default nm-hm" onClick={() => { this.update_code_shoulder("UB") }} >UB</button></td>
                                    <td><button title="Left Shoulder Hunched Forward" className="btn btn-default nm-hm" onClick={() => { this.update_code_shoulder("HL") }} >HL</button></td>
                                    <td><button title="Right Shoulder Hunched Forward" className="btn btn-default nm-hm" onClick={() => { this.update_code_shoulder("HR") }} >HR</button></td>
                                    <td><button title="Both Shoulder Hunched Forward" className="btn btn-default nm-hm" onClick={() => { this.update_code_shoulder("HB") }} >HB</button></td>
                                    <td><button title="Left Shoulder Shrugging(Up Down)-Dynamic" className="btn btn-default nm-hm" onClick={() => { this.update_code_shoulder("SL") }} >SL</button></td>
                                    <td><button title="Right Shoulder Shrugging(Up Down)-Dynamic" className="btn btn-default nm-hm" onClick={() => { this.update_code_shoulder("SR") }} >SR</button></td>
                                    <td><button title="Both Shoulder Shrugging(Up Down)-Dynamic" className="btn btn-default nm-hm" onClick={() => { this.update_code_shoulder("SB") }} >SB</button></td>

                                </tr>
                                <tr>
                                    <td onClick={() => { this.setState({ hnm_head: '' }) }}> Head : </td>
                                    <td><button title="Nodding(Up Down)-Dynamic" className="btn btn-default nm-hm" onClick={() => { this.update_code_head("NO") }} >NO</button></td>
                                    <td><button title="Shaking(Left Right)-Dynamic" className="btn btn-default nm-hm" onClick={() => { this.update_code_head("SH") }} >SH</button></td>
                                    <td><button title="Turned Right" className="btn btn-default nm-hm" onClick={() => { this.update_code_head("SR") }} >SR</button></td>
                                    <td><button title="Turned Left" className="btn btn-default nm-hm" onClick={() => { this.update_code_head("SL") }} >SL</button></td>
                                    <td><button title="Tilted Right" className="btn btn-default nm-hm" onClick={() => { this.update_code_head("TR") }} >TR</button></td>
                                    <td><button title="Tilted Left" className="btn btn-default nm-hm" onClick={() => { this.update_code_head("TL") }} >TL</button></td>
                                    <td><button title="Tilted Forward" className="btn btn-default nm-hm" onClick={() => { this.update_code_head("NF") }} >NF</button></td>
                                    <td><button title="Tilted Backward" className="btn btn-default nm-hm" onClick={() => { this.update_code_head("NB") }} >NB</button></td>
                                    <td><button title="Pushed Forward" className="btn btn-default nm-hm" onClick={() => { this.update_code_head("PF") }} >PF</button></td>
                                    <td><button title="Pushed Backward" className="btn btn-default nm-hm" onClick={() => { this.update_code_head("PB") }} >PB</button></td>
                                    <td><button title="Head Movement Linked to(Dynamic) Eye Gaze(Dynamic)" className="btn btn-default nm-hm" onClick={() => { this.update_code_head("LI") }} >LI</button></td>
                                    <td><button title="NU-Dynamic" className="btn btn-default nm-hm" onClick={() => { this.update_code_head("NU") }} >NU</button></td>
                                    <td><button title="ND-Dynamic" className="btn btn-default nm-hm" onClick={() => { this.update_code_head("ND") }} >ND</button></td>
                                </tr>

                                <tr>
                                    <td onClick={() => { this.setState({ hnm_nose: '' }) }}> Nose : </td>
                                    <td><button title="Wrinkled Nose" className="btn btn-default nm-hm" onClick={() => { this.update_code_nose("WR") }} >WR</button></td>
                                    <td><button title="Twitching Nose-Dynamic" className="btn btn-default nm-hm" onClick={() => { this.update_code_nose("TW") }} >TW</button></td>
                                    <td><button title="Widened Nostrils" className="btn btn-default nm-hm" onClick={() => { this.update_code_nose("WI") }} >WI</button></td>

                                </tr>


                                <tr>
                                    <td onClick={() => { this.setState({ hnm_eyegaze: '' }) }}> Eye Gaze : </td>
                                    <td><button title="Towards Addressee" className="btn btn-default nm-hm" onClick={() => { this.update_code_eye_gaze("AD") }} >AD</button></td>
                                    <td><button title="Far" className="btn btn-default nm-hm" onClick={() => { this.update_code_eye_gaze("FR") }} >FR</button></td>
                                    <td><button title="Towards Signer's Own Hands-Handcostellation" className="btn btn-default nm-hm" onClick={() => { this.update_code_eye_gaze("HD") }} >HD</button></td>
                                    <td><button title="Towards Signer's Own Dominant Hands" className="btn btn-default nm-hm" onClick={() => { this.update_code_eye_gaze("HI") }} >HI</button></td>
                                    <td><button title="Towards Signer's Own Non-Dominant Hands" className="btn btn-default nm-hm" onClick={() => { this.update_code_eye_gaze("HC") }} >HC</button></td>
                                    <td><button title="UP" className="btn btn-default nm-hm" onClick={() => { this.update_code_eye_gaze("UP") }} >UP</button></td>
                                    <td><button title="Down" className="btn btn-default nm-hm" onClick={() => { this.update_code_eye_gaze("DN") }} >DN</button></td>
                                    <td><button title="Left" className="btn btn-default nm-hm" onClick={() => { this.update_code_eye_gaze("LE") }} >LE</button></td>
                                    <td><button title="Right" className="btn btn-default nm-hm" onClick={() => { this.update_code_eye_gaze("RI") }} >RI</button></td>
                                    <td><button title="No Target-Unfocused" className="btn btn-default nm-hm" onClick={() => { this.update_code_eye_gaze("NO") }} >NO</button></td>
                                    <td><button title="Rolling Eyes -Dynamic" className="btn btn-default nm-hm" onClick={() => { this.update_code_eye_gaze("RO") }} >RO</button></td>
                                    <td><button title="LEft Up" className="btn btn-default nm-hm" onClick={() => { this.update_code_eye_gaze("LU") }} >LU</button></td>
                                    <td><button title="Right Up" className="btn btn-default nm-hm" onClick={() => { this.update_code_eye_gaze("RU") }} >RU</button></td>
                                    <td><button title="LEft Down" className="btn btn-default nm-hm" onClick={() => { this.update_code_eye_gaze("LD") }} >LD</button></td>
                                    <td><button title="Right Down" className="btn btn-default nm-hm" onClick={() => { this.update_code_eye_gaze("RD") }} >RD</button></td>
                                </tr>

                                <tr>
                                    <td align="left" onClick={() => { this.setState({ hnm_eyebrows: '' }) }}> EyeBrows : </td>
                                    <td><button title="Both Eyebrows Raised" className="btn btn-default nm-hm" onClick={() => { this.update_code_eye_brows("RB") }} >RB</button></td>
                                    <td><button title="Right  Eyebrow Raised" className="btn btn-default nm-hm" onClick={() => { this.update_code_eye_brows("RR") }} >RR</button></td>
                                    <td><button title="Left  Eyebrows Raised" className="btn btn-default nm-hm" onClick={() => { this.update_code_eye_brows("RL") }} >RL</button></td>
                                    <td><button title=" Eyebrows Furrowed" className="btn btn-default nm-hm" onClick={() => { this.update_code_eye_brows("FU") }} >FU</button></td>
                                </tr>


                                <tr>
                                    <td onClick={() => { this.setState({ hnm_eyelids: '' }) }}> Eye Lids : </td>
                                    <td><button title="Wide Open Eye Lids" className="btn btn-default nm-hm" onClick={() => { this.update_code_eye_lids("WB") }} >WB	</button></td>
                                    <td><button title="Wide Open Right Eyelid" className="btn btn-default nm-hm" onClick={() => { this.update_code_eye_lids("WR") }} >WR</button></td>
                                    <td><button title="Wide Open Left Eyelid" className="btn btn-default nm-hm" onClick={() => { this.update_code_eye_lids("WL") }} >WL</button></td>
                                    <td><button title="Narrowed, Almost closed Eyelids" className="btn btn-default nm-hm" onClick={() => { this.update_code_eye_lids("SB") }} >SB</button></td>
                                    <td><button title="Narrowed, Almost closed Right Eyelid" className="btn btn-default nm-hm" onClick={() => { this.update_code_eye_lids("SR") }} >SR</button></td>
                                    <td><button title="Narrowed, Almost closed Left Eyelid" className="btn btn-default nm-hm" onClick={() => { this.update_code_eye_lids("SL") }} >SL</button></td>
                                    <td><button title="Closed Eyelids" className="btn btn-default nm-hm" onClick={() => { this.update_code_eye_lids("CB") }} >CB</button></td>
                                    <td><button title="Closed Right Eyelid" className="btn btn-default nm-hm" onClick={() => { this.update_code_eye_lids("CR") }} >CR</button></td>
                                    <td><button title="Closed Left Eyelid" className="btn btn-default nm-hm" onClick={() => { this.update_code_eye_lids("CL") }} >CL</button></td>
                                    <td><button title="Tightly Shut Eyelids" className="btn btn-default nm-hm" onClick={() => { this.update_code_eye_lids("TB") }} >TB</button></td>
                                    <td><button title="Tightly Shut Right Eyelid" className="btn btn-default nm-hm" onClick={() => { this.update_code_eye_lids("TR") }} >TR</button></td>
                                    <td><button title="Tightly Shut Left Eyelid" className="btn btn-default nm-hm" onClick={() => { this.update_code_eye_lids("TL") }} >TL</button></td>
                                    <td><button title="Eye Blink(At the very end of the sign)-Dynamic" className="btn btn-default nm-hm" onClick={() => { this.update_code_eye_lids("BB") }} >BB</button></td>

                                </tr>
                            </tbody></table>











                    </TabPane>

                </Tabs>



                <div className="box box-default">
                    <Box boxShadow={2} padding={1} margin={1} className="box-header with-border bg-orange" >
                        <Grid spacing={1} container>
                            
                            <Grid item md={3}>
                                <button onClick={() => { this.generateSiGML() }} className="btn btn-xs margin1">Test</button>

                                <button onClick={() => { this.playAgain() }} className="btn btn-xs margin1">Re-Play</button>
                            </Grid>
                            <Grid item md={9} style={{textAlign:"end"}}>
                                <button onClick={() => { this.clearData() }} className="btn btn-xs">Reset</button>

                            </Grid>
                        </Grid>
                    </Box>
                    <div className="box-body">
                        <div className="form-group">
                            <Typography>{JSON.stringify(this.state.nmham)}</Typography>
                            <textarea
                                className="form-control hm-txt"
                                style={{ width: '100%' }}
                                ref = {this.textarea}
                                id="txt"
                                rows="4"
                                wrap="soft"
                                value={this.state.hamcode}
                                onChange={(event) => {this.handleHamCodeChange(event)}}
                                onBlur = {() => {this.handleFocus()}}
                            ></textarea>


                        </div>



                    </div>
                </div>
            </div >
        );
    }
}
export default withStyles(useStyles)(KeyBoard);
