import React from 'react';
import ReactDOM from 'react-dom';

import { Route, BrowserRouter as Router } from 'react-router-dom';

import './index.css';
import * as serviceWorker from './serviceWorker';
// import SignIn from './MyApp/SignIn';
import Index from './MyApp/index';
import WordList from './MyApp/SectionWord';

const routing = (
  <Router>
    <div>
      <Route exact path="/" component={Index} />
      <Route exact path="/list" component={WordList} />
    </div>
  </Router>
)

function getCookie(name) {
  var value = "; " + document.cookie;
  var parts = value.split("; " + name + "=");
  if (parts.length >= 2) return parts.pop().split(";").shift();

 
}
var role=getCookie('RoleType');
if( role==='Admin' || role==='ToolGuest'){
}
else
{

  //window.location='https://login.learnisl.com/';
 // window.location='http://localhost:3001/';


}

ReactDOM.render(
  routing,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
