import React, { Component, Fragment } from 'react'
import Button from '@material-ui/core/Button'
import { Grid } from '@material-ui/core'
import KeyBoard from './KeyBoard'
import Avatar from './Avatar'
import Navigation from './Navigation'

export default class index extends Component {
    render() {
        return (
            <Fragment>
                <Navigation />
                <Grid container>
                    <Grid item xs={7}>
                        <KeyBoard />
                    </Grid>
                    <Grid item xs={5}>
                        <Avatar />
                    </Grid>

                </Grid>

            </Fragment>
        )
    }
}
