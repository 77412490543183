import API from './API';

import axios from 'axios';

export default class dbconenction {
    dbconenction() {
        console.log('dbconnection');
    }
    async authenticate(uemail, upswd) {
        var valid = 0;

        const data = new FormData();
        data.append("email", uemail);
        data.append("pswd", upswd);

        const headers = {
            'Accept': 'application/json, text/plain, */*',
            "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8"
        }
        await axios.post(API.Apiserveraddress + '/Authenticate.php', data, {
            headers: headers
        }
        )
            .then((res) => {
                res = res.data;
                console.log(res);
                if (res.Success === true) {
                    valid = res;
                }
                else {
                    alert("Error: Incorrect Username or Password");
                }

            }).catch(function (Error) {
                //this.toggleLoadingProgress();
                alert("Could not connect to the server ");
            });

        return valid;
    }

    async GenerateSiGML(ham, nmham) {
       // var nmham =  {hnm_mouthgesture: 'C02'};//{name:'hnm_mouthgesture',value:'C02'};
        //
        const data = new FormData();
        //data.append("jwt", this.getJwt());
        data.append("ham", ham);
        data.append("nmham", JSON.stringify(nmham));

        var result = null;
        const headers = {
            'Accept': 'application/json, text/plain, */*',
            "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8"
        }
        await axios.post(API.Apiserveraddress + 'GetSiGMLHamTool.php', data, {
            headers: headers
        }

        )
            .then((res) => {
                result = res.data;

            }).catch(function (Error) {
                alert("Could not connect to the server ");
            });

        return result;
    }

    async UpdateSign(id, ham, nmham) {
        // var nmham =  {hnm_mouthgesture: 'C02'};//{name:'hnm_mouthgesture',value:'C02'};
         //
         const data = new FormData();
         //data.append("jwt", this.getJwt());
         data.append("ham", ham);
         data.append("nmham", nmham);
         data.append("id", id);
 
         var result = null;
         const headers = {
             'Accept': 'application/json, text/plain, */*',
             "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8"
         }
         await axios.post(API.Apiserveraddress + 'GenerateSiGML.php', data, {
             headers: headers
         }
 
         )
             .then((res) => {
                 result = res.data;
 
             }).catch(function (Error) {
                 alert("Could not connect to the server ");
             });
 
         return result;
     }
 
 
 

    
    async loadAllWords() {
       
         var result = null;
         const headers = {
             'Accept': 'application/json, text/plain, */*',
             "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8"
         }
         await axios.get(API.Apiserveraddress + 'GetWordList.php', {
             headers: headers
         }
 
         )
             .then((res) => {
                 result = res.data;
 
             }).catch(function (Error) {
                 alert("Could not connect to the server ");
             });
 
         return result;
     }

     

   getJwt() {
        return "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwOlwvXC9leGFtcGxlLm9yZyIsImF1ZCI6Imh0dHA6XC9cL2V4YW1wbGUub3JnIiwiaWF0IjoxMzU2OTk5NTI0LCJuYmYiOjEzNTcwMDAwMDAsImRhdGEiOnsidWlkIjoiMiIsInVuYW1lIjoiYW1hbiIsImN1cnJlbnRfZGF0ZSI6IjIwMjAtMDctMTUgMTQ6NDc6MTEiLCJlbWFpbCI6ImFiY0BnbWFpbC5jb20ifX0.i2CIegJ3B_s8vvoCbEWasCGC0FDq1333OEHiKfke8QY";
    }
}