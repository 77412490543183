import { AppBar, Toolbar, withStyles } from '@material-ui/core'
import { Button, Typography } from 'antd'
import React, { Component } from 'react'

const useStyles = theme => ({
    title: {
        flexGrow: 1,
    },
    button: {
        margin: 1,
    }

})

class Navigation extends Component {
    render() {
        const { classes } = this.props;

        return (
            <AppBar position="static" style={{ background: '#FADF99' }}>
                <Toolbar>
                    <Typography className={classes.title}>
                        HamNoSys Tool
                    </Typography>
                    
                    <Button variant="h6" className={classes.button} >
                        Home
                    </Button>
                    <Button color="inherit" className={classes.button}>
                        Login
                    </Button>
                </Toolbar>
            </AppBar>
        )
    }
}

export default withStyles(useStyles)(Navigation);
